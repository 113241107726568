import React, { FC } from 'react';
import { AppBar, SxProps, Toolbar, Stack } from '@mui/material';
import { useParams } from 'react-router';
import { gql, useQuery } from '@apollo/client';

import { Breadcrumbs } from './Breadcrumbs';

/*
 * Types.
 */

interface NavigationBarProps {
  sx?: SxProps;
}

const MARKETPLACE_BREADCRUMB_QUERY = gql`
  query MarketplaceBreadcrumb($venueSlug: String!, $spaceSlug: String) {
    marketplaceBreadcrumb(venueSlug: $venueSlug, spaceSlug: $spaceSlug) {
      spaceSlug
      spaceName
      venueSlug
      venueName
      organizationSlug
      organizationName
    }
  }
`;

/*
 * Components.
 */

export const NavigationBar: FC<NavigationBarProps> = ({ sx = {} }) => {
  const { spaceSlug, venueSlug } = useParams();

  const { data: breadcrumbData } = useQuery(MARKETPLACE_BREADCRUMB_QUERY, {
    variables: {
      venueSlug,
      spaceSlug,
    },
    skip: !venueSlug,
  });

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: '#fff',
        boxShadow: '0px 1px 0px 0px #0000001A',
        ...sx,
      }}
    >
      <Toolbar>
        <Stack
          gap="30px"
          direction={{ xs: 'column', md: 'row' }}
          alignItems={{
            xs: 'start',
            md: 'center',
          }}
          margin={{
            xs: '16px 0',
          }}
        >
          <img
            src="/pv-logo-marketplace.png"
            alt="Perfect Venue Logo"
            height={'32px'}
          />

          {breadcrumbData && (
            <Breadcrumbs
              breadcrumb={breadcrumbData.marketplaceBreadcrumb}
              page={spaceSlug ? 'space' : 'venue'}
            />
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
