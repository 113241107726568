import React, { FC } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import { Dayjs } from 'dayjs';

import type {
  EventStyle,
  GuestRange,
} from '../../types/components/filter_bar_types';
import type { VoidFn, Nullable, Procedure } from '../../types/common';
import { WebFilterBar } from './WebFilterBar';
import { MobileFilterBar } from './MobileFilterBar';
import type {
  PartialExpressBookSettings,
  HoursOfOperation,
} from '../../types/models/venue_types';

/*
 * Types
 */

export interface FilterBarProps {
  eventDate: Nullable<Dayjs>;
  onChangeEventDate: VoidFn<Nullable<Dayjs>>;

  startTime: Nullable<number>;
  onChangeStartTime: VoidFn<SelectChangeEvent<number>>;
  clearStartTime: Procedure;

  endTime: Nullable<number>;
  onChangeEndTime: VoidFn<SelectChangeEvent<number>>;
  clearEndTime: Procedure;

  eventStyle: EventStyle;
  onChangeEventStyle: VoidFn<SelectChangeEvent<EventStyle>>;
  clearEventStyle?: Procedure;

  guestRange: GuestRange;
  onChangeGuestRange: VoidFn<SelectChangeEvent<GuestRange>>;
  clearGuestRange?: Procedure;

  expressBookSettings?: PartialExpressBookSettings;
  hoursOfOperation?: HoursOfOperation;
}

export const FilterBar: FC<FilterBarProps> = (props: FilterBarProps) => {
  const theme = useTheme();
  const mobileMedia = useMediaQuery(theme.breakpoints.down('lg'));

  return mobileMedia ? (
    <MobileFilterBar {...props} />
  ) : (
    <WebFilterBar {...props} />
  );
};
