import React, { FC } from 'react';
import {
  Box,
  Button,
  IconButton,
  Dialog,
  Stack,
  Typography,
} from '@mui/material';
import { X } from 'react-feather';

import type { FilterBarProps } from './index';
import type { Procedure } from '../../types/common';
import { EventDatePicker } from './pickers/EventDatePicker';
import { EventTimePicker } from './pickers/EventTimePicker';
import { EventStylePicker } from './pickers/EventStylePicker';
import { GuestRangePicker } from './pickers/GuestRangePicker';

/*
 * Types.
 */

interface MobileFilterBarDialogProps extends FilterBarProps {
  isOpen: boolean;
  onClose: Procedure;
}

/*
 * Components.
 */

export const MobileFilterBarDialog: FC<MobileFilterBarDialogProps> = ({
  isOpen,
  onClose,
  eventDate,
  onChangeEventDate,
  startTime,
  onChangeStartTime,
  clearStartTime,
  endTime,
  onChangeEndTime,
  clearEndTime,
  eventStyle,
  onChangeEventStyle,
  clearEventStyle,
  guestRange,
  onChangeGuestRange,
  clearGuestRange,
  expressBookSettings,
  hoursOfOperation,
}: MobileFilterBarDialogProps) => {
  const handleClearSelection = () => {
    onChangeEventDate(null);
    clearStartTime();
    clearEndTime();
    if (clearEventStyle) {
      clearEventStyle();
    }
    if (clearGuestRange) {
      clearGuestRange();
    }
  };

  const renderDialogHeader = () => (
    <Stack
      direction="row"
      gap="10px"
      alignItems="center"
      justifyContent="center"
      sx={{ margin: '16px 20px' }}
    >
      <IconButton onClick={onClose} title="close">
        <X />
      </IconButton>
      <Typography variant="h6" sx={{ width: '100%', textAlign: 'center' }}>
        Filter
      </Typography>
    </Stack>
  );

  const renderDialogFooter = () => (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      padding="24px"
      sx={{ boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.10) inset' }}
    >
      <Button
        color="primary"
        variant="outlined"
        sx={{ textTransform: 'none' }}
        onClick={handleClearSelection}
      >
        Clear Selection
      </Button>
      <Button
        color="secondary"
        variant="contained"
        sx={{ textTransform: 'none' }}
        onClick={onClose}
      >
        Apply Filter
      </Button>
    </Stack>
  );

  const renderDialogBody = () => (
    <Stack direction="column" gap="24px" padding="24px" alignItems="center">
      <Stack direction="column" gap="16px" width="368px">
        <Typography variant="h6">Select Date</Typography>
        <EventDatePicker
          eventDate={eventDate}
          onChangeEventDate={onChangeEventDate}
          onClose={onClose}
          shouldRenderActionBar={false}
          shouldCloseOnChange={false}
          expressBookSettings={expressBookSettings}
          hoursOfOperation={hoursOfOperation}
        />
      </Stack>
      <Box width="368px">
        <EventTimePicker
          eventDate={eventDate}
          startTime={startTime}
          endTime={endTime}
          onChangeStartTime={onChangeStartTime}
          onChangeEndTime={onChangeEndTime}
          clearStartTime={clearStartTime}
          clearEndTime={clearEndTime}
          onClose={onClose}
          shouldRenderActionBar={false}
          expressBookSettings={expressBookSettings}
          hoursOfOperation={hoursOfOperation}
        />
      </Box>
      <Stack direction="column" gap="16px" width="368px">
        <Typography variant="h6">Style</Typography>
        <EventStylePicker
          eventStyle={eventStyle}
          onChangeEventStyle={onChangeEventStyle}
          styleProps={{ height: '56px' }}
          shouldShowOutlineSelector
          shouldRenderIcon
        />
      </Stack>
      <Stack direction="column" gap="16px" width="368px">
        <Typography variant="h6">Guests</Typography>
        <GuestRangePicker
          guestRange={guestRange}
          onChangeGuestRange={onChangeGuestRange}
          styleProps={{ height: '56px' }}
          shouldShowOutlineSelector
          shouldRenderIcon
        />
      </Stack>
    </Stack>
  );

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen>
      <Stack
        direction="column"
        justifyContent="space-between"
        height="100%"
        sx={{ marginTop: '30px', marginBottom: '32px' }}
      >
        {renderDialogHeader()}
        {renderDialogBody()}
        {renderDialogFooter()}
      </Stack>
    </Dialog>
  );
};
