import React from 'react';
import {
  useParams,
  useSearchParams,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { LoadingIndicator } from '@pv/common/components';

import { EventSummary } from '../EventSummary';
import { EventInfoForm } from './EventInfoForm';
import { StepCounter } from '../StepCounter';
import { EventRequestPageHeader } from '../EventRequestPageHeader';
import {
  TwoColumnLayout,
  ColumnContainer,
  PageContainer,
  SpaceAndEventInfoContainer,
} from '../styledComponents';
import { VENUE_BOOKINGS_QUERY, SPACE_BOOKINGS_QUERY } from '../graphql';
import { Event, CustomField } from '../types';
import { HowExpressBookWorks } from './HowExpressBookWorks';
import { NavigationBar } from '../NavigationBar';
import { FooterBar } from '../../../components/FooterBar';
import { Stack, Divider, Hidden } from '@mui/material';

export const VenueBookingsPage = () => {
  const { venueSlug, spaceSlug } = useParams();
  const [URLSearchParams] = useSearchParams();
  const origin = URLSearchParams.get('origin') || '';
  const startDate = URLSearchParams.get('startDate') || '';
  const startOffset = parseInt(URLSearchParams.get('startOffset') || '0');
  const endOffset = parseInt(URLSearchParams.get('endOffset') || '0');

  const navigate = useNavigate();
  const isExpressBook = origin === 'express_book';

  const { data: venueData, loading: venueLoading } = useQuery(
    VENUE_BOOKINGS_QUERY,
    {
      variables: { venueSlug },
    },
  );

  const { data: spaceData, loading: spaceLoading } = useQuery(
    SPACE_BOOKINGS_QUERY,
    {
      variables: { spaceSlug },
    },
  );

  if (venueLoading || spaceLoading) {
    return <LoadingIndicator />;
  }

  if (!venueData || !spaceData) {
    return <Navigate to="/not-found" />;
  }
  const venue = venueData.marketplaceVenue;
  const space = spaceData.marketplaceSpace;

  const getMaxGroupSize = () => {
    const seated = space.seated || 0;
    const standing = space.standing || 0;
    return Math.max(seated, standing);
  };

  const maxGroupSize = getMaxGroupSize();

  const handleCompletedStep = (event: Event) => {
    if (isExpressBook) {
      navigate(
        `/venues/${venue.slug}/spaces/${space.slug}/bookings/${event.slug}`,
      );
    } else {
      navigate(
        `/venues/${venue.slug}/spaces/${space.slug}/bookings/request-sent`,
      );
    }
  };

  return (
    <>
      <NavigationBar />
      <PageContainer>
        {isExpressBook ? (
          <StepCounter activeStepIndex={0} />
        ) : (
          <EventRequestPageHeader headerText="Send Event Request" />
        )}
        <TwoColumnLayout container={true} spacing={2}>
          <ColumnContainer item={true} xs={12} sm={6}>
            <EventInfoForm
              venueSlug={venue.slug}
              spaceSlug={space.slug}
              onCompleted={handleCompletedStep}
              origin={isExpressBook ? 'express_book' : 'marketplace'}
              buttonText={isExpressBook ? 'Continue' : 'Send Request'}
              minGroupSize={venue.minGroupSize}
              maxGroupSize={maxGroupSize}
              customFields={venue.userFieldDefinitions.filter(
                (ufd: CustomField) =>
                  isExpressBook ? ufd.visibleOnExpressBook : ufd.visible,
              )}
            />
            {isExpressBook && (
              <Hidden smUp>
                <HowExpressBookWorks />
              </Hidden>
            )}
          </ColumnContainer>

          <ColumnContainer item={true} xs={12} sm={6}>
            <SpaceAndEventInfoContainer>
              <EventSummary
                space={space}
                venue={venue}
                startDate={startDate}
                startOffset={startOffset}
                endOffset={endOffset}
              />
            </SpaceAndEventInfoContainer>
            {isExpressBook && (
              <Hidden smDown>
                <HowExpressBookWorks />
              </Hidden>
            )}
          </ColumnContainer>
        </TwoColumnLayout>
        <Stack sx={{ flex: 1, justifyContent: 'end' }}>
          <Divider />
          <FooterBar />
        </Stack>
      </PageContainer>
    </>
  );
};
