import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { useStripeLoader } from '@pv/common/hooks';
import { ExpressBookCheckoutForm } from './ExpressBookCheckoutForm';
import { CREATE_SETUP_INTENT_MUTATION } from '../graphql';
import { useMutation } from '@apollo/client';
import { PageLoading } from '@pv/common/components';

export const ExpressBookCheckoutPage = () => {
  const stripePromise = useStripeLoader();
  const navigate = useNavigate();
  const { eventSlug, venueSlug, spaceSlug } = useParams();
  const [clientSecret, setClientSecret] = useState<string | undefined>();
  const [createSetupIntent, { loading }] = useMutation(
    CREATE_SETUP_INTENT_MUTATION,
    {
      onCompleted: (data) => {
        if (data?.createSetupIntent?.stripeClientSecret) {
          setClientSecret(data.createSetupIntent.stripeClientSecret);
        } else {
          redirectToSuccessPage();
        }
      },
      onError: (error) => {
        console.error(error);
        redirectToSuccessPage();
      },
    },
  );

  const redirectToSuccessPage = () => {
    navigate(
      `/venues/${venueSlug}/spaces/${spaceSlug}/bookings/${eventSlug}/success`,
    );
  };

  useEffect(() => {
    const input = {
      eventSlug,
    };
    createSetupIntent({ variables: { input } });
  }, []);

  if (!eventSlug || !venueSlug) {
    return null;
  }

  if (loading) {
    return <PageLoading />;
  }

  if (!clientSecret) {
    return null;
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: clientSecret,
      }}
    >
      <ExpressBookCheckoutForm />
    </Elements>
  );
};
