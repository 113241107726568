import React, { FC, ReactNode } from 'react';
import { Box, Stack, Toolbar, Typography, Link } from '@mui/material';
import styled from 'styled-components';
import { Instagram, Facebook, Linkedin } from 'react-feather';

import { PVGray, PVWhite, PVRed } from '@pv/common/colors';

/*
 * Types.
 */

interface FooterBarProps {}

interface FooterLinkProps {
  href: string;
  title?: string;
  children: ReactNode;
}

/*
 * Constants.
 */

const StyledFooterIconAnchor = styled.a`
  color: ${PVWhite};
`;

const StyledOrangeIconBackgroundDiv = styled.div`
  width: 32px;
  height: 32px;
  background: ${PVRed};
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const FooterLink: FC<FooterLinkProps> = ({ href, children }) => (
  <Link
    href={href}
    target="_blank"
    rel="noopener,noreferrer"
    variant="body2"
    underline="hover"
    color={PVGray}
  >
    {children}
  </Link>
);

const FooterIcon: FC<FooterLinkProps> = ({ title, href, children }) => (
  <StyledFooterIconAnchor
    href={href}
    aria-label={title}
    target="_blank"
    rel="noopener,noreferrer"
  >
    <StyledOrangeIconBackgroundDiv>{children}</StyledOrangeIconBackgroundDiv>
  </StyledFooterIconAnchor>
);

/*
 * Components.
 */

export const FooterBar: FC<FooterBarProps> = () => (
  <Box
    position="static"
    sx={{
      backgroundColor: PVWhite,
      boxShadow: 'none',
    }}
  >
    <Toolbar disableGutters>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        gap="32px"
        margin={{ xs: '16px 0px', sm: '16px 0px', md: '0px' }}
        sx={{ width: '100%', justifyContent: 'space-between' }}
      >
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          gap={{ xs: '10px', sm: '10px', md: '32px' }}
        >
          <Typography variant="body2" color={PVGray}>
            &copy; 2024 Perfect Venue
          </Typography>
          <FooterLink href="https://www.perfectvenue.com/terms-of-service">
            Terms of Service
          </FooterLink>
          <FooterLink href="https://www.perfectvenue.com/privacy-policy">
            Privacy Policy
          </FooterLink>
        </Stack>
        <Stack direction="row" gap="16px">
          <FooterIcon
            href="https://www.instagram.com/perfectvenueco/"
            title="Follow us on Instagram"
          >
            <Instagram />
          </FooterIcon>
          <FooterIcon
            href="https://www.facebook.com/perfectvenueco"
            title="Find us on Facebook"
          >
            <Facebook />
          </FooterIcon>
          <FooterIcon
            href="https://www.linkedin.com/company/perfect-venue"
            title="Connect with us on LinkedIn"
          >
            <Linkedin />
          </FooterIcon>
        </Stack>
      </Stack>
    </Toolbar>
  </Box>
);
