import React, { FC, useState, useEffect } from 'react';
import { useParams, Navigate, useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import dayjs, { Dayjs } from 'dayjs';
import { Button, Divider, Stack, Typography, Box } from '@mui/material';
import { HashLink } from 'react-router-hash-link';

import { LoadingIndicator } from '@pv/common/components';

import type { Nullable } from '../../types/common';
import { FilterBar } from '../../components/FilterBar';
import type {
  EventStyle,
  GuestRange,
} from '../../types/components/filter_bar_types';
import {
  parseEventStyle,
  parseGuestRange,
} from '../../types/components/filter_bar_types';
import { fetchVenue } from '../../helpers/api/graphql';
import { ProfileHeader } from '../../components/ProfileHeader';
import { buildAvailableSpacesRequest } from '../../helpers/api/spaces';
import { buildMappableAddressCoord } from '../../helpers/api/addresses';
import { fetchAvailableSpaces } from '../../helpers/api/graphql';
import { SpacesShelf } from '../../components/SpacesShelf';
import { GoogleMap } from '../../components/GoogleMap';
import { ProfileFooter } from '../../components/ProfileFooter';
import { Icon } from '../../components/Icon';
import { VenueSeoMarkup } from './VenueSeoMarkup';
import { ContactUsButton } from '../../components/ContactUsButton';

/*
 * Constants.
 */

const mapStyle = {
  width: '100%',
  height: '320px',
  borderRadius: '12px',
};

/*
 * Components.
 */

export const VenueSpacesPage: FC = () => {
  const { venueSlug } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [eventDate, setEventDate] = useState<Nullable<Dayjs>>(
    searchParams.get('eventDate') ? dayjs(searchParams.get('eventDate')) : null,
  );
  const [startOffset, setStartOffset] = useState<Nullable<number>>(
    searchParams.get('startOffset')
      ? Number(searchParams.get('startOffset'))
      : null,
  );
  const [endOffset, setEndOffset] = useState<Nullable<number>>(
    searchParams.get('endOffset')
      ? Number(searchParams.get('endOffset'))
      : null,
  );
  const [eventStyle, setEventStyle] = useState<EventStyle>(
    (searchParams.get('eventStyle') as EventStyle) || 'seated_or_standing',
  );
  const [guestRange, setGuestRange] = useState<GuestRange>(
    (searchParams.get('guestRange') as GuestRange) || 'any_number',
  );

  const {
    data: venueData,
    loading: venueLoading,
    error,
  } = useQuery(fetchVenue, {
    variables: {
      slug: venueSlug,
    },
    skip: !venueSlug,
  });

  const {
    data: availableSpacesData,
    refetch,
    loading: spacesLoading,
  } = useQuery(fetchAvailableSpaces, {
    variables: {
      venueSlug,
    },
    skip: !venueSlug,
  });

  useEffect(() => {
    if (!venueSlug) {
      return;
    }

    refetch(
      buildAvailableSpacesRequest({
        venueSlug,
        eventDate,
        startOffset,
        endOffset,
        eventStyle,
        guestRange,
      }),
    );
  }, [
    refetch,
    venueSlug,
    eventDate,
    startOffset,
    endOffset,
    eventStyle,
    guestRange,
  ]);

  useEffect(() => {
    const params = {
      ...(eventDate ? { eventDate: eventDate.toISOString() } : {}),
      ...(startOffset !== null ? { startOffset: String(startOffset) } : {}),
      ...(endOffset !== null ? { endOffset: String(endOffset) } : {}),
      eventStyle,
      guestRange,
    };
    setSearchParams(params);
  }, [
    eventDate,
    startOffset,
    endOffset,
    eventStyle,
    guestRange,
    setSearchParams,
  ]);

  if (venueLoading) {
    return <LoadingIndicator />;
  }

  if (error && error.graphQLErrors[0]?.message === 'No venue found') {
    return <Navigate to="/not-found" />;
  }

  const venue = venueData?.marketplaceVenue;

  if (!venue) {
    return <Navigate to="/not-found" />;
  }

  const mappableVenue = {
    key: venue.slug,
    name: venue.name,
    position: buildMappableAddressCoord(venue.address),
  };

  const renderAboutVenue = () => (
    <Stack
      direction={{
        sm: 'column-reverse',
        md: 'row',
      }}
      gap="48px"
      justifyContent="space-between"
    >
      <Stack direction="column" gap="24px" width={{ sm: '100%', md: '710px' }}>
        <Typography variant="h2" sx={{ fontWeight: '700', fontSize: '24px' }}>
          About the venue
        </Typography>
        <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
          {venue.description ?? 'Tell us about this venue...'}
        </Typography>
      </Stack>
      <Stack
        direction="column"
        gap="24px"
        width={{ sm: '100%', md: '300px', lg: '300px' }}
      >
        <HashLink smooth to="#FindYourEventSpace">
          <Button
            variant="contained"
            color="secondary"
            sx={{ height: '50px', width: '100%', fontSize: '16px' }}
          >
            Check Availability
          </Button>
        </HashLink>
        <ContactUsButton leadFormUrl={venue.helloLeadFormUrl} />

        {venue.menu?.menuItems.length > 0 && (
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<Icon name="menu" />}
            sx={{ height: '50px', fontSize: '16px' }}
            href={`/venues/${venue.slug}/menu`}
          >
            View Menu
          </Button>
        )}
      </Stack>
    </Stack>
  );

  const renderFindEventSpace = () => (
    <Stack direction="column">
      <Typography
        id="FindYourEventSpace"
        variant="h2"
        sx={{ fontWeight: '700', fontSize: '24px' }}
      >
        Find your event space
      </Typography>
      {venue.expressBookSettings.marketplaceEnabled && (
        <Stack direction="column" alignItems="center" width="100%">
          <FilterBar
            eventDate={eventDate}
            onChangeEventDate={setEventDate}
            startTime={startOffset}
            onChangeStartTime={(e) => setStartOffset(Number(e.target.value))}
            clearStartTime={() => setStartOffset(null)}
            endTime={endOffset}
            onChangeEndTime={(e) => setEndOffset(Number(e.target.value))}
            clearEndTime={() => setEndOffset(null)}
            eventStyle={eventStyle}
            onChangeEventStyle={(e) =>
              setEventStyle(parseEventStyle(e.target.value))
            }
            clearEventStyle={() => setEventStyle('seated_or_standing')}
            guestRange={guestRange}
            onChangeGuestRange={(e) =>
              setGuestRange(parseGuestRange(e.target.value))
            }
            clearGuestRange={() => setGuestRange('any_number')}
            expressBookSettings={venue.expressBookSettings}
            hoursOfOperation={venue.hoursOfOperation}
          />
        </Stack>
      )}
      {spacesLoading ? (
        <LoadingIndicator />
      ) : (
        <SpacesShelf
          spaces={availableSpacesData.availableSpaces}
          previewWidth="556"
          gridTemplateColumns={{
            xs: '1fr',
            sm: '1fr',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(2, 1fr)',
          }}
        />
      )}
    </Stack>
  );

  const renderVenueLocation = () => (
    <Stack direction="column" gap="24px">
      <Typography
        variant="h2"
        sx={{
          fontWeight: 700,
          fontSize: '24px',
          lineHeight: '123.5%',
        }}
      >
        Location
      </Typography>
      <GoogleMap
        mapStyle={mapStyle}
        center={buildMappableAddressCoord(venue.address)}
        mappableItems={[mappableVenue]}
      />
    </Stack>
  );

  return (
    <>
      <VenueSeoMarkup venueSlug={venue.slug as string} />
      <header>
        <ProfileHeader venue={venue} />
      </header>
      <main>
        <Stack
          sx={{
            padding: '32px',
            margin: '0px auto',
            width: {
              md: '94%',
              lg: '1200px',
            },
            gap: '32px',
          }}
        >
          {renderAboutVenue()}
          <Divider />
          {renderFindEventSpace()}
          <Divider />
          {renderVenueLocation()}
          <Divider />
        </Stack>
      </main>
      <footer>
        <Box
          sx={{
            padding: '32px',
            margin: '0px auto',
            width: {
              md: '94%',
              lg: '1200px',
            },
            gap: '32px',
          }}
        >
          <ProfileFooter />
        </Box>
      </footer>
    </>
  );
};
