import React from 'react';

import {
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  Box,
  FormControlLabel,
  Typography,
  Stack,
} from '@mui/material';

import { moneyPrint } from '@pv/common/utils';
import { Choice, Option } from '../types';

interface ChoiceListProps {
  choice: Choice;
  label: string;
  selectedOptionIds: string[];
  setSelectedOptionIds: (choiceOptions: string[]) => void;
}
export const ChoiceList = ({
  choice,
  label,
  selectedOptionIds,
  setSelectedOptionIds,
}: ChoiceListProps) => {
  const relevantSelectedOptionIds = React.useMemo(() => {
    // if there is more than one "choice" on this contract item, the
    // limit only applies to the number of options selected for this
    // particular choice

    return selectedOptionIds.filter((id) =>
      choice.options.map((option) => option.id).includes(id),
    );
  }, [selectedOptionIds, choice.options]);

  const irrelevantSelectedOptionIds = React.useMemo(() => {
    return selectedOptionIds.filter(
      (id) => !choice.options.map((option) => option.id).includes(id),
    );
  }, [selectedOptionIds, choice.options]);

  const getOptionLabel = (option: Option) => {
    return (
      <Stack direction="row" gap="16px">
        <Typography variant="body2">{option.name}</Typography>
        {option.price && (
          <Typography variant="body2" color="textSecondary">
            +{moneyPrint(option.price / 100)}
          </Typography>
        )}
      </Stack>
    );
  };
  return (
    <Box>
      <FormControl component="fieldset">
        <FormLabel component="legend">
          <Typography
            variant="subtitle2"
            sx={{
              padding: '5px 0',
            }}
          >
            {label}
          </Typography>
        </FormLabel>
        <FormGroup sx={{ marginLeft: '16px' }}>
          {choice.options.map((option) => (
            <FormControlLabel
              key={option.id}
              control={
                <Checkbox
                  color="secondary"
                  checked={selectedOptionIds.includes(option.id)}
                  onChange={(e) => {
                    if (e.target.checked) {
                      if (
                        choice.limit &&
                        relevantSelectedOptionIds.length >= choice.limit
                      ) {
                        setSelectedOptionIds([
                          ...irrelevantSelectedOptionIds,
                          ...relevantSelectedOptionIds.slice(1),
                          option.id,
                        ]);
                      } else {
                        setSelectedOptionIds([...selectedOptionIds, option.id]);
                      }
                    } else {
                      setSelectedOptionIds(
                        selectedOptionIds.filter((id) => id !== option.id),
                      );
                    }
                  }}
                />
              }
              label={getOptionLabel(option)}
            />
          ))}
        </FormGroup>
      </FormControl>
    </Box>
  );
};
