import React from 'react';

import { Typography, Stack } from '@mui/material';
import { ItsAlmostTimeToParty } from '../ItsAlmostTimeToParty';
export const MarketplaceEventSuccessPage = () => (
  <Stack
    direction="column"
    justifyContent="center"
    alignItems="center"
    spacing={2}
    sx={{ height: '540px' }}
  >
    <Typography
      variant="h1"
      sx={{
        fontSize: '30px',
        lineHeight: '34.5px',
        fontWeight: '700',
      }}
    >
      Request Sent
    </Typography>
    <ItsAlmostTimeToParty />
    <Typography
      variant="body1"
      sx={{
        fontSize: '20px',
        lineHeight: '30px',
        fontWeight: '400',
      }}
    >
      The venue will get back to you soon via email.
    </Typography>
  </Stack>
);
