import React, { FC } from 'react';
import { Typography, Link } from '@mui/material';

/*
 * Types.
 */

interface VenueLocationDetailsLinkProps {
  venueName?: string;
  venueSlug?: string;
  venueAddress?: string | null;
  fontSize?: string;
  venueNameColor?: string;
  venueAddressColor?: string;
  boldName?: boolean;
}

interface VenueNameLinkProps {
  venueName: string;
  venueSlug?: string;
  fontSize?: string;
  color?: string;
  bold: boolean;
}

/*
 * Components.
 */

const VenueNameLink: FC<VenueNameLinkProps> = ({
  venueName,
  venueSlug,
  fontSize,
  color,
  bold,
}: VenueNameLinkProps) => (
  <Link
    variant="body2"
    fontSize={fontSize}
    color={color}
    underline="hover"
    href={venueSlug && `/venues/${venueSlug}`}
  >
    {bold ? <strong>{`${venueName} `}</strong> : `${venueName} `}
  </Link>
);

export const VenueLocationDetailsLink: FC<VenueLocationDetailsLinkProps> = ({
  venueName,
  venueSlug,
  venueAddress,
  fontSize,
  venueNameColor,
  venueAddressColor,
  boldName,
}: VenueLocationDetailsLinkProps) => {
  if (!venueName) {
    return null;
  }

  if (venueAddress) {
    return (
      <Typography variant="body2" color={venueAddressColor} fontSize={fontSize}>
        <VenueNameLink
          venueName={venueName}
          venueSlug={venueSlug}
          fontSize={fontSize}
          color={venueNameColor}
          bold={Boolean(boldName)}
        />
        {'\u2022'} {venueAddress}
      </Typography>
    );
  }

  return (
    <VenueNameLink
      venueName={venueName}
      venueSlug={venueSlug}
      fontSize={fontSize}
      color={venueNameColor}
      bold={Boolean(boldName)}
    />
  );
};
