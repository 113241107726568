import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { NavigationBar } from '../NavigationBar';
import { FooterBar } from '../../../components/FooterBar';
import { PageContainer } from '../styledComponents';
import { StepCounter } from '../StepCounter';
import { ItsAlmostTimeToParty } from '../ItsAlmostTimeToParty';
import { SEND_EXPRESS_BOOK_REQUEST_MUTATION } from '../graphql';
import { useStripeLoader } from '@pv/common/hooks';

export const ExpressBookConfirmationPage = () => {
  const { eventSlug } = useParams();
  const stripePromise = useStripeLoader();

  if (!eventSlug) {
    return null;
  }

  return (
    <Elements stripe={stripePromise}>
      <ExpressBookConfirmationPageContents />
    </Elements>
  );
};

export const ExpressBookConfirmationPageContents = () => {
  const stripe = useStripe();
  const { eventSlug, venueSlug } = useParams();
  const [URLSearchParams] = useSearchParams();
  const stripeClientSecret =
    URLSearchParams.get('setup_intent_client_secret') || '';
  const setupIntentId = URLSearchParams.get('setup_intent') || '';
  const signature = URLSearchParams.get('signature') || '';

  const [sendExpressBookRequest] = useMutation(
    SEND_EXPRESS_BOOK_REQUEST_MUTATION,
  );
  useEffect(() => {
    if (
      !stripe ||
      !stripeClientSecret ||
      !setupIntentId ||
      !eventSlug ||
      !signature
    ) {
      return;
    }
    stripe.retrieveSetupIntent(stripeClientSecret).then(({ setupIntent }) => {
      if (!setupIntent) {
        return;
      }
      if (setupIntent.status === 'succeeded') {
        sendExpressBookRequest({
          variables: { input: { eventSlug, signature } },
        });
      }
    });
  }, [stripe, stripeClientSecret]);

  return (
    <>
      <NavigationBar />

      <PageContainer>
        <StepCounter activeStepIndex={3} />

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <ItsAlmostTimeToParty />
          <Typography variant="body1">
            The venue has up to <strong> 24 hours </strong> to respond to your
            event request.
          </Typography>
          <Typography variant="body1">
            You will receive an email when they respond.
          </Typography>
          <Button
            sx={{ marginTop: '24px' }}
            variant="outlined"
            color="secondary"
            href={`/venues/${venueSlug}`}
          >
            Return to Venue
          </Button>
        </Stack>
        <div style={{ flexGrow: 1 }} />
        <div>
          <Divider />
          <FooterBar />
        </div>
      </PageContainer>
    </>
  );
};
