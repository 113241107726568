import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Button, Stack, Typography } from '@mui/material';
import { Calendar } from 'react-feather';
import { Dayjs } from 'dayjs';
import { startCase } from 'lodash';

import { PVRed, PVGray, PVWhite, PVLightGray } from '@pv/common/colors';
import { offsetToTimeString } from '@pv/common/utils';

import { guestRangeToDisplayNameMap } from './selectors/GuestRangeSelector';
import type { FilterBarProps } from './index';
import { Icon } from '../Icon';
import type { Nullable } from '../../types/common';
import { MobileFilterBarDialog } from './MobileFilterBarDialog';

/*
 * Constants.
 */

const StyledIconBackgroundDiv = styled.div`
  width: 40px;
  height: 40px;
  background: ${PVRed};
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

/*
 * Components.
 */

export const MobileFilterBar: FC<FilterBarProps> = ({
  eventDate,
  onChangeEventDate,
  startTime,
  onChangeStartTime,
  clearStartTime,
  endTime,
  onChangeEndTime,
  clearEndTime,
  eventStyle,
  onChangeEventStyle,
  clearEventStyle,
  guestRange,
  onChangeGuestRange,
  clearGuestRange,
  expressBookSettings,
  hoursOfOperation,
}: FilterBarProps) => {
  const [open, setOpen] = useState(false);
  const handleClick = (event: any) => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div role="search" style={{ width: '103%' }}>
      <Button
        sx={{
          height: '70px',
          margin: '16px 0px 16px 0px',
          '&.MuiButton-root:hover': {
            backgroundColor: 'transparent',
          },
        }}
        fullWidth
        disableRipple
        onClick={handleClick}
      >
        <Stack
          direction="row"
          width="100%"
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            textAlign: 'left',

            padding: '10px',
            paddingLeft: '20px',

            borderRadius: '50px',
            border: `1px solid ${PVLightGray}`,
            background: PVWhite,

            boxShadow: '0px 2px 14px 0px rgba(0, 0, 0, 0.10)',
          }}
        >
          <Stack direction="row" gap="12px" alignItems="center">
            <Calendar />
            <Stack direction="column">
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '143%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {displayDateAndTime(eventDate, startTime, endTime)}
              </Typography>
              <Typography
                variant="body2"
                color={PVGray}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {`${startCase(eventStyle)}, ${
                  guestRangeToDisplayNameMap[guestRange]
                }`}
              </Typography>
            </Stack>
          </Stack>
          <StyledIconBackgroundDiv>
            <Icon name="filters" size={20} />
          </StyledIconBackgroundDiv>
        </Stack>
      </Button>
      <MobileFilterBarDialog
        isOpen={open}
        onClose={handleClose}
        eventDate={eventDate}
        onChangeEventDate={onChangeEventDate}
        startTime={startTime}
        onChangeStartTime={onChangeStartTime}
        clearStartTime={clearStartTime}
        endTime={endTime}
        onChangeEndTime={onChangeEndTime}
        clearEndTime={clearEndTime}
        eventStyle={eventStyle}
        onChangeEventStyle={onChangeEventStyle}
        clearEventStyle={clearEventStyle}
        guestRange={guestRange}
        onChangeGuestRange={onChangeGuestRange}
        clearGuestRange={clearGuestRange}
        expressBookSettings={expressBookSettings}
        hoursOfOperation={hoursOfOperation}
      />
    </div>
  );
};

/*
 * Helpers.
 */

function displayDateAndTime(
  eventDate: Nullable<Dayjs>,
  startTime: Nullable<number>,
  endTime: Nullable<number>,
) {
  if (!eventDate && !startTime && !endTime) {
    return 'Any Date & Time';
  }

  const displayDate = eventDate?.format('MMM DD, YYYY') ?? 'Any Date';

  if (!startTime || !endTime) {
    return `${displayDate} at Any Time`;
  }

  const displayStartTime = offsetToTimeString(startTime);
  const displayEndTime = offsetToTimeString(endTime);

  return `${displayDate} at ${displayStartTime} - ${displayEndTime}`;
}
